const EntitiesMenu = [
  {
    heading: "menu",
    route: "/course-batch",
    pages: [
      {
        heading: "Search Batch",
        route: "/course-batch/search",
        fontIcon: "fa-search",
      },
      {
        heading: "New Course Batch",
        route: "/course-batch/batchInfo",
        fontIcon: "fa-plus",
      },
      {
        heading: "Running Course Batch",
        route: "/course-batch/running-batch",
        fontIcon: "fa-list",
      },
      {
        sectionTitle: "Batch Mapping",
        route: "/course-batch/mapping",
        fontIcon: "fa-plus",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        sub: [
          {
            heading: "Map Trainer",
            route: "/course-batch/mapping/trainer",
          },
          {
            heading: "Map Assessor",
            route: "/course-batch/mapping/assessor",
          },
          {
            heading: "Map Trainee",
            route: "/course-batch/mapping/trainee",
          },
        ],
      },
    ],
  },
];

export default EntitiesMenu;
